export enum Status {
  Active = 1,
  Inactive = 0,
}

export enum permissions {
  RESOURCE_MANAGEMENT = 1,
  LANGUAGE_MANAGEMENT = 2,
  CONFIG_MANAGEMENT = 3,
  PERMISSION_MANAGEMENT = 4,
  USER_MANAGEMENT = 5,
}

export enum IsSystem {
  Yes = 1,
  No = 0,
}

export enum IsVisible {
  Yes = 1,
  No = 0,
}

export enum ConfigKeys {
  RESOURCE_VERSION = 'RESOURCE_VERSION',
  LANGUAGE_VERSION = 'LANGUAGE_VERSION',
  DISCOUNT_SUB_MENU = 'DISCOUNT_PRICE_SUB_MENU',
  DISCOUNT_SUB_MENU_SECOND = 'DISCOUNT_SUB_MENU_SECOND',
  POINT_REFERAL = 'POINT_REFERAL',
  POINT_REFUND_BOOKING = 'POINT_REFUND_BOOKING',
  POINT_ADD_FEEDBACK = 'POINT_ADD_FEEDBACK',
  POINT_REFERAL_FIRST_BOOKING_BODY_REMOVAL = 'POINT_REFERAL_FIRST_BOOKING_BODY_REMOVAL',
  POINT_REFERAL_FIRST_BOOKING_DRNS = 'POINT_REFERAL_FIRST_BOOKING_DRNS',
  POINT_REFERAL_TICKET = 'POINT_REFERAL_TICKET',
  POINT_EXCHANGE_RATE = 'POINT_EXCHANGE_RATE',
  POINT_PER_PACKAGE = 'POINT_PER_PACKAGE',
  POINT_WITHDRAW_RATE = 'POINT_WITHDRAW_RATE',
  POINT_REFERAL_FIRST_BOOKING_REMOVAL = 'POINT_REFERAL_FIRST_BOOKING_REMOVAL',
}

export enum ACTIVE {
  TRUE = 1,
  FALSE = 0,
}

export enum LANGUAGE {
  JA = 'ja',
  EN = 'en',
}

export enum TYPEPICKER {
  TIME = 'time',
  DATE = 'date',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
  DAY = 'day',
}

export enum TYPEINPUT {
  MULTIPLE = 'multiple',
}

export enum GenderType {
  MALE = 1,
  FEMALE = 2,
}

export enum DAYOFWEEK {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export enum OptionType {
  Normal = 1,
  Other = 2,
}

//API enum
export enum UserStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum MemberStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum AccountStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum AccountType {
  NEW = 1,
  OLD = 2,
}

export enum ClinicBranchStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum BookingStatus {
  BOOKING = 1,
  ARRIVED = 2,
  CONSULTING = 3,
  EXAMINING = 4,
  PAYING = 5,
  NOT_WASHING_FACE = 6,
  WAITING_FOR_TREATMENT = 7,
  BEING_TREATED = 8,
  TREATED = 9,
  AFTER_PAYMENT = 10,
  DONE = 11,
  PAID = 12,
  ANESTHESIA = 13,
  CANCELLED = 98,
  CANCELLED_2 = 99,
  BLOCK = 100,
}

export enum PositionType {
  DOCTOR = 1,
  NURSE = 2,
}

export enum IsRead {
  True = 1,
  False = 2,
}

export enum Permissions {
  RESOURCE_MANAGEMENT = 1,
  LANGUAGE_MANAGEMENT = 2,
  CONFIG_MANAGEMENT = 3,
  PERMISSION_MANAGEMENT = 4,
  USER_MANAGEMENT = 5,
}

export enum MedicalExaminationType {
  ONLINE = 1,
  OFFLINE = 2,
  FREE = 3,
}

export enum AspirationType {
  DOCTOR = 1,
  NURSE = 2,
}

export enum AgeType {
  GTE_20YO = 1,
  LTE_19YO = 2,
}

export enum MedicalRecordStatus {
  DEFAULT = 1,
  IN_PROGRESS = 2,
  FINISHED = 3,
  CANCEL = 4,
}

export enum PlanStatus {
  DEFAULT = 1,
  BOOKING_CREATED = 2,
  FINISHED = 3,
  CANCEL = 0, //For test
}

export enum ServiceGroupType {
  DEFAULT = 1,
  DOCTOR = 2,
  NURSE = 3,
}

export enum FunctionType {
  SG = 1,
  SL = 2,
  M = 3,
  G = 4,
  D = 5,
  N = 6,
  CSO = 7, //tu van online
  CSN = 8, // tu van offline
}

export enum RoomType {
  EXAMINATION = 1, //tri lieu
  CONSULT_OFF = 2, //tu van off
  CONSULT_ONL = 3, //tu van onl
}

export enum MachineRoomType {
  S = 'S',
  M = 'M',
  D = 'D',
  N = 'N',
}
export enum RoomStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum BookingType {
  EXAMINATION = 1,
  CONSULT = 2,
  TRIAL_EXAMINATION = 3,
  BILL = 4,
}

export enum ScheduleType {
  PERIODIC = 1,
  UNEXPECTED = 2,
}

export enum TimeOffType {
  BRANCH_TIME_OFF = 1,
  ROOM_TIME_OFF = 2,
  STAFF_TIME_OFF = 3,
}

export enum NotificationType {
  BOOKING_SUCCESS = 1,
  BOOKING_CANCEL = 2,
  BOOKING_UPDATED = 3,
  BOOKING_FINISHED = 4,
  BOOKING_REMINDED = 5,
  RECOMMENDED_BOOKING_INCOMING = 6,
  ROOM_OFF = 7,
}

export enum Day {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export enum CommonStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum VerifiedCodeStatus {
  UNUSED = 1,
  USED = 2,
}

export enum MedicalRecordUsageType {
  PLAN = 1,
  FREE = 2,
}

export enum RoomFunctionType {
  EXAM_NORMAL = 1,
  CONSULT_OFF = 2,
  CONSULT_ONL = 3,
  FREE_FOR_DOCTOR = 4,
  FREE_FOR_NURSE = 5,
}

export enum IsMeetDirector {
  MEET = 1,
  NOT_MEET = 0,
}

export enum MemberMemoType {
  TODO = 1,
  NOTE = 2,
}

export enum TARGET_TYPE {
  ERROR = 1,
  MEMBER = 2,
  BOOKING = 3,
  STEP = 4,
  COURSE = 5,
}

export enum ACTION_TYPE {
  ADD = 1,
  UPDATE = 2,
  DELETE = 3,
}

export enum LOG_TYPE {
  LOG_ACTION = 1,
  LOG_ERROR = 2,
}

export enum CREATED_TYPE {
  USER = 1,
  ADMIN = 2,
}

export const LogDescription = {
  ADD_MEMBER: `Add new member`,
  UPDATE_MEMBER: `Update member`,
  ADD_COURSE: `Add new course for member`,
  ADD_STEP: `Add new ticket/step for member`,
  UPDATE_STEP: `Update step for member`,
  DELETE_STEP: `Remove ticket/step for member`,
  CREATE_BOOKING: `Create booking for member`,
  UPDATE_BOOKING: `Update booking for member`,
  CANCEL_BOOKING: `Cancel booking for member`,
};
export enum NotificationAdminType {
  EMAIL = 1,
  NOTIFICATION = 2,
}

export enum NotificationStatusType {
  DRAFT = 1,
  COMEPLETE = 0,
}

export enum NotificationRecipientType {
  ALL = 1,
  FEW_MEMBER = 2,
}

export enum ActionModalNotification {
  CREATE = 1,
  EDIT = 2,
  VIEW,
}

export enum ActionModalPathological {
  CREATE = 1,
  EDIT = 2,
  VIEW,
}

export enum ActionModalCommon {
  CREATE = 1,
  EDIT = 2,
  VIEW = 3,
  DELETE = 4,
}

export const CODE_BILL = 'BILL';
export const DURATION_BILL = 15;

export enum PermissionsCode {
  CREATE_MEMO = 'CREATE_MEMO',
  MOVE_TIME_OFF = 'MOVE_TIME_OFF',
  ROOM_MANAGEMENT = 'ROOM_MANAGEMENT',
  FEEDBACK_MANAGEMENT = 'FEEDBACK_MANAGEMENT',
}

export const BEGIN_WORK_TIME = 11;
export const END_WORK_TIME = 20;

export enum OPTION_MEDICAL_TYPE {
  DISEASE_FACE = 1,
  HAIR_REMOVAL = 2,
}

export enum PointHistoryType {
  DEPOSIT = 1, // Mua Point
  ADMIN_CHANGE = 2, // Do admin cộng trừ
  POINT_REFERAL_FIRST_BOOKING_BODY_REMOVAL = 3, //Point giới thiệu khi booking triệt lông lần đầu
  POINT_REFERAL_FIRST_BOOKING_DRNS = 4, // được giới thiệu khi booking drns lần đầu
  POINT_REFERAL_TICKET = 5, // được giới thiệu khi tạo ticket
  BOOKING_USE = 6, // point thanh toán booking
  FEEDBACK = 7, // được cộng khi feedback
  IMPORT = 8, // import từ hệ thống cũ
  BOOKING_BONUS = 9, // nhận được khi booking
  WITHDRAW = 10, // rút tiền
  REFUND = 11, // Hoàn lại point khi hủy ticket
  REVOKE = 12, // Thu hồi lại point bonus khi hủy ticket
  EXPIRE = 13,
  POINT_REFERAL_FIRST_BOOKING_REMOVAL = 15,
}

export enum PointPackageType {
  WITHDRAW = 1, // quy đổi point
  DEPOSIT = 2, // nạp point
}

export enum WithdrawStatus {
  REQUESTED = 0,
  REJECTED = 1,
  ADMIN_CONFIRMED = 2,
}

export enum PointHistoryUsageType {
  BUY = 1,
  REFUND = 2,
  PAY = 3,
  BONUS = 4,
  REVOKE = 5,
  EXPIRE = 6,
}

export enum BillBookingStatus {
  PAID = 1, // Đã thanh toán
  UNPAID = 2, // Chưa thanh toán
}

export enum PointActionUserType {
  DEPOSIT = 1,
  WITHDRAW = 2,
  CHANGE_MANUAL = 3,
}
export enum ReceiptDetailCategory {
  Service = 1,
  Item = 2,
  Therapy = 3,
  Refund = 4,
  Consult = 5,
  Insurance = 6,
  BuyPoint = 7,
  RefundFee = 8,
  BonusPoint = 9,
  Other = 99,
}

export enum ReceiptDetailType {
  Consult = 1,
  BuyCourse = 2,
  UseCourse = 3,
  Therapy = 4,
  BuyPoint = 5,
  Item = 6,
  Refund = 7,
  Purchase = 8,
}

export enum CardPaymentType {
  CreditCard = 1,
  ECommerce = 2,
  Stripe = 3,
  Flex = 4,
  VISA = 5,
  MasterCard = 6,
  JCB = 7,
  AmericanExpress = 8,
  Diners = 9,
}

export enum CardPaymentMethod {
  ONE_TIME = 1,
  TWICE = 2,
  SPLIT = 3,
}

export enum ReceiptStatus {
  INACTIVE = 0,
  ACTIVE = 1,
}

export enum BankAccountType {
  NORMAL = 1,
  TEMPORARY = 2,
}

export enum PlanPurchaseStatus {
  PAID = 1, // Đã thanh toán
  UNPAID = 2, // Chưa thanh toán
  MUST_POSTPAID = 3, // Bắt buộc phải thanh toán sau
}

export const RE_IRRADIATION_SERVICE = [414, 415, 416, 417, 418];

export const S3_CHECKIN_URL = 'https://beautyskinclinic.s3.ap-northeast-1.amazonaws.com/checkin/no_{0}.mp3';

export const BRANCHES = [
  {
    id: 1,
    title: '新宿院',
  },
  {
    id: 2,
    title: '渋谷院',
  },
  {
    id: 3,
    title: '池袋院',
  },
];

export const SHORT_BRANCHES = [
  {
    id: 0,
    title: '全院',
  },
  {
    id: 1,
    title: '新宿',
  },
  {
    id: 2,
    title: '渋谷',
  },
  {
    id: 3,
    title: '池袋',
  },
];

export const ANESTHESIAN_OPTION = '麻酔クリーム（院内セルフ塗布）';

export const UsedType = {
  PLAN: 1,
  TRIAL: 2,
  FIRST_TRIAL: 3,
};

export enum PAYMENT_METHOD {
  ALL = 0,
  CASH = 1, // 現金
  CARD, // カード
  BANK_TRANSFER, // 銀行振込
  PAYPAY, // PAYPAY
  ELECTRONIC_MONEY, // 電子マネー
  FLEX, // フレックス
  POCKET_CARD, // ポケットカード
  ADJUSTMENT, // 調整（入金無）
  STRIPE = 9, // ストライプ
}

export enum PAYMENT_METHOD_TYPE {
  BANK_FLEX_POCKET = 1,
  PAYPAY_ADJUSTMENT_ELECTRONIC_MONEY = 2,
}

export enum TypeBooking {
  NOT_DRAFT = 0,
  DRAFT = 1,
}

export enum DisPlayStatus {
  NONE = 0,
  FULL = 1,
  PARTIAL = 2,
}

export const Room_Doctor = [29, 35, 38, 44, 72, 74];

export const Room_Nurse = [60, 81, 87, 61, 88, 82, 30];
